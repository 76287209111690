import React from 'react';
import { bool, func, node, number, string } from 'prop-types';
import classNames from 'classnames';
import { IconClose } from '../../../components';

import { FormattedMessage } from '../../../util/reactIntl';
import { parse } from '../../../util/urlHelpers';
import { isOriginInUse, parseSelectFilterOptions } from '../../../util/search';
import { pickSearchParamsOnly} from '../SearchPage.shared';

import css from './MainPanelHeader.module.css';

const format = (selectedOptions, queryParamName, schemaType, searchMode) => {
  const hasOptionsSelected = selectedOptions && selectedOptions.length > 0;
  const mode = schemaType === 'multi-enum' && searchMode ? `${searchMode}:` : '';
  const value = hasOptionsSelected ? `${mode}${selectedOptions.join(',')}` : null;

  return { [queryParamName]: value };
};

const MainPanelHeader = props => {
  const {
    rootClassName,
    className,
    children,
    sortByComponent,
    isSortByActive,
    listingsAreLoaded,
    resultsCount,
    searchInProgress,
    noResultsInfo,
    getHandleChangedValueFn,
    urlQueryParams,
    selectedFilters,
    config,
    filterConfigs,
    selectedFiltersArray
  } = props;



  const handleFilterRemove = (e, values) => {

    e.preventDefault();
    const queryParamName = values.filterId === 'price' || values.filterId === 'dates' ? values.filterId : 'pub_' + values.filterId;

    const { ...searchInURL } = parse(props.location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    const urlQueryParams = pickSearchParamsOnly(searchInURL, filterConfigs, config.search.sortConfig, isOriginInUse(config));

    const selectedFiltersArray = parseSelectFilterOptions(urlQueryParams[queryParamName]);

    let usedValue = [];
    let schemaType = '';
    let searchMode = '';

    if(queryParamName !== 'price' && queryParamName !== 'dates') {
      usedValue = selectedFiltersArray.filter(s => s !== values.option);
      schemaType = 'multi-enum';
      searchMode = 'has_any';
    }

    getHandleChangedValueFn(format(usedValue, queryParamName, schemaType, searchMode));
  };


  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.searchOptions}>
        <h1 className={css.searchOptionsTitle}>Discover holistic services</h1>

        {/*<div className={css.searchResultSummary}>*/}
        {/*  <span className={css.resultsFound}>*/}
        {/*    {searchInProgress ? (*/}
        {/*      <FormattedMessage id="MainPanelHeader.loadingResults" />*/}
        {/*    ) : (*/}
        {/*      <FormattedMessage*/}
        {/*        id="MainPanelHeader.foundResults"*/}
        {/*        values={{ count: resultsCount }}*/}
        {/*      />*/}
        {/*    )}*/}
        {/*  </span>*/}
        {/*</div>*/}
        {/*{isSortByActive ? (*/}
        {/*  <div className={css.sortyByWrapper}>*/}
        {/*    <span className={css.sortyBy}>*/}
        {/*      <FormattedMessage id="MainPanelHeader.sortBy" />*/}
        {/*    </span>*/}
        {/*    {sortByComponent}*/}
        {/*  </div>*/}
        {/*) : null}*/}
      </div>

      {children}

      {selectedFiltersArray?.length > 0 && <>
        {/*<h3 className={css.selectedFiltersTitle}><FormattedMessage id={'SearchPage.yourFilters'} /></h3>*/}
        <div className={css.selectedFiltersHolder}>
          <ul className={css.selectedFiltersList}>
            {selectedFiltersArray?.map((el, index) => {
              return <li key={index} className={css.selectedFiltersItem}>
                {el.label}
                <IconClose
                  onClick={e => handleFilterRemove(e, el)}
                  rootClassName={css.closeIcon} size="small"
                />
              </li>
            })}
          </ul>
        </div>
      </>}

      {/*{noResultsInfo ? noResultsInfo : null}*/}
    </div>
  );
};

MainPanelHeader.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchInProgress: false,
  sortByComponent: null,
};

MainPanelHeader.propTypes = {
  rootClassName: string,
  className: string,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  sortByComponent: node,
};

export default MainPanelHeader;
